import { site as en_site } from "../data/en/data";

const l = [];
l["en"] = en_site;

// langugage select
const searchParams = new URLSearchParams(window.location.search);
const lng = searchParams.has("lng") ? searchParams.get("lng") : "en";

const data = l[lng];

export { data };
