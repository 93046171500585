import "./Footer.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import useStore from "../../store";

function Footer(props) {
  const el = useRef();
  const tl = useRef();

  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);

  useEffect(() => {
    // stuff
  }, []);

  const footerLinkClicked = (e) => {
    const link = e.currentTarget.dataset.link;
    const external = e.currentTarget.dataset.external === "true";

    if (external) {
      window.open(link);
    } else {
      setAppState("/" + link);
    }
  };

  return (
    <div ref={el} className={`footer`}>
      <div className="footer-inner">
        <div className="footer-headings">
          {data.footer.rows.map((r, l) => {
            return r.headings.map((h, i) => {
              return (
                <div
                  key={"footer-heading-" + i + l}
                  className={`heading ${h.style || ""}`}
                >
                  {h.title}
                </div>
              );
            });
          })}
        </div>
        <div className="footer-content">
          {data.footer.rows.map((r, l) => {
            return r.headings.map((h, k) => {
              if (h.description) {
                return (
                  <div
                    key={"footer-content-" + k}
                    className={`content ${h.style || ""}`}
                  >
                    {h.description}
                  </div>
                );
              } else {
                return (
                  <div
                    key={"footer-content-" + k}
                    className={`content ${h.style || ""}`}
                  >
                    {h.links.map((l, j) => {
                      return (
                        <button
                          key={"footer-link-" + j}
                          onClick={footerLinkClicked}
                          className="footer-link"
                          data-link={l.link}
                          data-external={l.external}
                        >
                          {l.title}
                        </button>
                      );
                    })}
                  </div>
                );
              }
            });
          })}
        </div>
      </div>
      <div className="footer-inner-mobile">
        {data.footer.rows.map((r, l) => {
          return (
            <div key={"footer-row-mobile-" + l} className="footer-row">
              <div className="footer-headings">
                {r.headings.map((h, i) => {
                  return (
                    <div
                      key={"footer-heading-" + i}
                      className={`heading ${h.style || ""}`}
                    >
                      {h.title}
                    </div>
                  );
                })}
              </div>
              <div className="footer-content">
                {r.headings.map((h, k) => {
                  if (h.description) {
                    return (
                      <div
                        key={"footer-content-" + k}
                        className={`content ${h.style || ""}`}
                      >
                        {h.description}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={"footer-content-" + k}
                        className={`content ${h.style || ""}`}
                      >
                        {h.links.map((l, j) => {
                          return (
                            <button
                              key={"footer-link-" + j}
                              onClick={footerLinkClicked}
                              className="footer-link"
                              data-link={l.link}
                              data-external={l.external}
                            >
                              {l.title === "Privacy and Terms & Conditions"
                                ? "Privacy and T&C"
                                : l.title}
                            </button>
                          );
                        })}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Footer;
