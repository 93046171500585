import { create } from "zustand";

const useStore = create((set) => ({
  appState: null, // landing
  setAppState: (string) => set(() => ({ appState: string })),

  pillIndex: null,
  setPillIndex: (val) => set(() => ({ pillIndex: val })),

  landingPill: null,
  setLandingPill: (val) => set(() => ({ landingPill: val })),

  storiesGridStyle: null,
  setStoriesGridStyle: (val) => set(() => ({ storiesGridStyle: val })),

  storiesGridSort: null,
  setStoriesGridSort: (val) => set(() => ({ storiesGridSort: val })),
}));

export default useStore;
