import "./Header.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import Menu from "../Menu/Menu";
import gsap from "gsap";
import useStore from "../../store";

function Header(props) {
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);

  const [story, setStory] = useState(null);

  const el = useRef();
  const tl = useRef();
  const hl = useRef();
  const hr = useRef();

  useEffect(() => {
    const slug = window.location.pathname.split("/").pop();
    if (slug) {
      // check for matching story and get data
      data.stories.stories.forEach((s, i) => {
        if (s.slug.toLowerCase() === slug.toLowerCase()) {
          setStory(data.stories.stories[i]);
          console.log(data.stories.stories[i]);
        }
      });
    }

    // animate the header in
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.5 });
    tl.current.fromTo(
      [hl.current, hr.current],
      { autoAlpha: 0, y: -50 },
      {
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        duration: 0.5,
        ease: "power4.out",
      }
    );
  }, []);

  const homeClicked = (e) => {
    if (appState === "/" || appState === "") return;
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.0 });
    tl.current.to([hl.current, hr.current, el.current], {
      autoAlpha: 0,
      y: -20,
      stagger: 0.1,
      duration: 0.5,
      ease: "power4.out",
      onComplete: () => {
        setAppState("/");
      },
    });
  };

  return (
    <div ref={el} className={`header`}>
      <div className="header-left" ref={hl}>
        <Menu />
      </div>
      <div className="header-right" ref={hr}>
        <button
          className="home-button"
          onClick={homeClicked}
          style={{ color: story ? story.colors.primary : "#111" }}
        >
          {data.header.url}
        </button>
      </div>
    </div>
  );
}

export default Header;
