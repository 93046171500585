import "./Privacy.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";

function Privacy(props) {
  const el = useRef();
  const tl = useRef();

  useEffect(() => {
    // stuff
  }, []);

  return (
    <div ref={el} className={`privacy`}>
      <h1 className="title">{parse(data.privacy.title)}</h1>
      <h1 className="mobile-title">{parse(data.privacy.mobileTitle)}</h1>
      <div className="content">
        <h2>{parse(data.privacy.date)}</h2>
        {data.privacy.content.map((c, i) => {
          return (
            <div className="content-block">
              <h3>{parse(c.title)}</h3>
              <p>{parse(c.text)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Privacy;
