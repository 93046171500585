import "./Story.scss";
import { Suspense, useEffect, useMemo, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import useStore from "../../store";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useDrag } from "react-use-gesture";
import { a, useSpring } from "@react-spring/three";
import {
  Environment,
  Html,
  PerspectiveCamera,
  Plane,
  SoftShadows,
  useGLTF,
  useProgress,
} from "@react-three/drei";
import { Player } from "@lottiefiles/react-lottie-player";
import loaderLottie from "../../assets/animations/loading.json";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-icon.svg";
import gsap from "gsap";
import AudioPlayer from "../AudioPlayer/AudioPlayer";

const compareByName = (a, b) => {
  return a.name.localeCompare(b.name);
};

const compareByStrength = (a, b) => {
  return a.strengthValue - b.strengthValue;
};
const compareByPopularity = (a, b) => {
  return a.popularity - b.popularity;
};

const PillModel = ({ model }) => {
  const pill = useRef();
  // const pillIndex = useStore((state) => state.pillIndex);

  const { nodes, materials, scene } = useGLTF(model.file);

  useEffect(() => {
    scene.traverse((mesh, i) => {
      mesh.castShadow = true;
      if (mesh.material) {
        if (mesh.material.metalness > 0.4) {
          mesh.material.metalness = 0.4;
        }
      }
    });
    // actions["animation_0"].timeScale = 0.5;
    // actions["animation_0"].play();
  }, [scene]);

  useFrame(({ gl, camera, clock }) => {
    //console.log(clock.getDelta());
    pill.current.rotation.y += 0.003;
  });
  const scale = model.scale || 8;
  return (
    <mesh castShadow ref={pill} scale={[scale, scale, scale]}>
      <primitive object={scene} />
    </mesh>
  );
};

const Loader = () => {
  const { progress } = useProgress();
  const lottiePlayer = useRef();

  return (
    <Html wrapperClass="loading-wrapper">
      <Player
        // animationData={animationData}
        src={loaderLottie}
        loop
        autoplay
        //ref={lottiePlayer}
        className="player"
        // height={400}
        // width={400}
      />
    </Html>
  );
};

function Inspector({ responsiveness = 20, children }) {
  const { size } = useThree();
  const euler = useMemo(() => new THREE.Euler(), []);
  const [spring, set] = useSpring(() => ({
    rotation: [0, 0, 0],
  }));
  const bind = useDrag(({ delta: [dx, dy] }) => {
    euler.y += (dx / size.width) * responsiveness;
    euler.x += (dy / size.width) * responsiveness;
    euler.x = THREE.MathUtils.clamp(euler.x, -Math.PI / 2, Math.PI / 2);
    set({ rotation: euler.toArray().slice(0, 3) });
  });
  return (
    <a.group {...bind()} {...spring}>
      {children}
    </a.group>
  );
}

function Story({ story }) {
  const el = useRef();
  const tl = useRef();
  const tl2 = useRef();
  const overviewButton = useRef();
  const backButton = useRef();
  const nextButton = useRef();
  const canvas = useRef();

  const title1 = useRef();
  const title2 = useRef();
  const title3 = useRef();

  const mobileMiniNav = useRef();
  const bottomInfo = useRef();

  const storiesGridSort = useStore((state) => state.storiesGridSort);

  const stories = data.stories.stories;

  useEffect(() => {
    switch (storiesGridSort) {
      case "name":
        stories.sort(compareByName);
        break;
      case "popularity":
        stories.sort(compareByPopularity);
        break;
      case "strength":
        stories.sort(compareByStrength);
        break;
      default:
        break;
    }

    // anaimte in page
    if (tl2.current) tl2.current.pause();
    tl2.current = gsap.timeline({ delay: 1 });
    tl2.current.set([title1.current, title2.current, title3.current], {
      autoAlpha: 0,
    });
    tl2.current.set([title1.current], { y: 100 });
    tl2.current.set([title3.current], { y: -100 });
    tl2.current.to(title2.current, {
      autoAlpha: 1,
      duration: 0.5,
      ease: "sine.in",
    });
    tl2.current.to([title1.current, title3.current], {
      y: 0,
      duration: 0.4,
      ease: "circ.out",
      autoAlpha: 1,
    });

    // mobile mini nav
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0.5],
    };

    const callback = (entries, observer) => {
      console.log(entries[0].intersectionRatio);
      if (entries[0].isIntersecting === false) {
        const tl3 = gsap.timeline();
        tl3.set(mobileMiniNav.current, { zIndex: 10, y: 10 });
        tl3.to(mobileMiniNav.current, { autoAlpha: 1, y: 0, duration: 0.5 });
      } else {
        const tl3 = gsap.timeline();
        tl3.to(mobileMiniNav.current, { autoAlpha: 0, y: 10, duration: 0.5 });
        tl3.set(mobileMiniNav.current, { zIndex: -1 });
      }
    };

    let observer = new IntersectionObserver(callback, options);
    observer.observe(bottomInfo.current);
  }, []);

  const quitButtonClicked = (e) => {
    // aniamte then jump to new page
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    tl.current.to(document.querySelector("#root"), {
      autoAlpha: 0,
      duration: 0.5,
    });
    tl.current.call(() => {
      window.location.href = "../../quit";
    });
  };

  const overviewClicked = (e) => {
    // aniamte then jump to new page
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    tl.current.to(document.querySelector("#root"), {
      autoAlpha: 0,
      duration: 0.5,
    });
    tl.current.call(() => {
      window.location.href = "../../stories";
    });
  };

  const nextClicked = (e) => {
    // find this storie in the array of stories
    let _i;
    stories.forEach((s, i) => {
      if (s.slug === story.slug) {
        _i = i;
      }
    });
    if (_i === stories.length - 1) {
      _i = 0;
    } else {
      _i += 1;
    }
    const slug = stories[_i].slug;
    // aniamte then jump to new page
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    tl.current.to(document.querySelector("#root"), {
      autoAlpha: 0,
      duration: 0.5,
    });
    tl.current.call(() => {
      window.location.href = "../../stories/" + slug;
    });
  };

  const backClicked = (e) => {
    // find this storie in the array of stories
    let _i;
    stories.forEach((s, i) => {
      if (s.slug === story.slug) {
        _i = i;
      }
    });
    if (_i === 0) {
      _i = stories.length - 1;
    } else {
      _i -= 1;
    }
    const slug = stories[_i].slug;
    // aniamte then jump to new page
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    tl.current.to(document.querySelector("#root"), {
      autoAlpha: 0,
      duration: 0.5,
    });
    tl.current.call(() => {
      window.location.href = "../../stories/" + slug;
    });
  };

  return (
    <div ref={el} className={`story`}>
      <div
        ref={mobileMiniNav}
        className="mobile-mini-nav"
        style={{ backgroundColor: story.colors.primary }}
      >
        <div className="button-row">
          <button
            className="back-button-mobile"
            onClick={backClicked}
            ref={backButton}
          >
            <ArrowIcon />
          </button>

          <img className="pill-image" src={story.image} alt="" />
          <div className="button-row-right">
            <button
              className="back-button"
              onClick={backClicked}
              ref={backButton}
            >
              <ArrowIcon />
            </button>
            <button
              className="next-button"
              onClick={nextClicked}
              ref={nextButton}
            >
              <ArrowIcon />
            </button>
          </div>
        </div>
      </div>
      <div
        className="story-left"
        style={{ backgroundColor: story.colors.primary }}
      >
        <div ref={canvas} className="pill-canvas">
          <Canvas shadows>
            <Suspense fallback={<Loader />}>
              <PerspectiveCamera
                makeDefault={true}
                fov={30}
                position={[0, 0, 18]}
              />
              <SoftShadows size={100} focus={0.7} samples={12} />

              <Environment files="../../assets/models/brown_photostudio_02_1k.hdr" />
              <directionalLight
                position={[0.3, 3, 3]}
                intensity={2.5}
              ></directionalLight>
              <directionalLight
                castShadow
                position={[0.2, 0.5, 3]}
                intensity={0.2}
                shadow-mapSize={1024}
              ></directionalLight>
              <Inspector>
                <PillModel model={story.model} />
              </Inspector>
              <Plane
                receiveShadow
                args={[40, 40]}
                rotation={[0, 0, 0]}
                position={[0, 0, -6]}
              >
                <shadowMaterial transparent opacity={0.4} color={0x000} />
              </Plane>
            </Suspense>
          </Canvas>
        </div>
        <div ref={bottomInfo} className="info">
          <div className="row row-0 title-row">
            <div className={`info-title cell-0`}>
              {data.stories.infoHeadings.genericName}
            </div>
          </div>
          <div className="row row-0 content-row">
            <div className={`info-content cell-0`}>
              {story.genericName.replace("<br />", " ")}
            </div>
          </div>
          <div className="row row-1 title-row">
            <div className={`info-title cell-0`}>
              {data.stories.infoHeadings.strengths}
            </div>
            <div className={`info-title cell-1`}>
              {data.stories.infoHeadings.availability}
            </div>
          </div>
          <div className="row row-1 content-row">
            <div className={`info-content cell-0`}>{story.strengths}</div>
            <div className={`info-content cell-1`}>{story.availability}</div>
          </div>
          <div className="row row-2 title-row desktop-only">
            <div className={`info-title cell-0`}>
              {data.stories.infoHeadings.csaSchedule}
            </div>
            <div className={`info-title cell-1`}>
              {data.stories.infoHeadings.potentialForAbuse}
            </div>
            <div className={`info-title cell-2`}>
              {data.stories.infoHeadings.approvalHistory}
            </div>
          </div>
          <div className="row row-2 content-row desktop-only">
            <div className={`info-content cell-0`}>{story.csaSchedule}</div>
            <div className={`info-content cell-1`}>
              {story.potentialForAbuse}
            </div>
            <div className={`info-content cell-2`}>{story.approvalHistory}</div>
          </div>

          <div className="row row-2 title-row title-row-mobile">
            <div className={`info-title cell-0`}>
              {data.stories.infoHeadings.csaSchedule}
            </div>
            <div className={`info-title cell-1`}>
              {data.stories.infoHeadings.potentialForAbuse}
            </div>
            {/* <div className={`info-title cell-2`}>
              {data.stories.infoHeadings.approvalHistory}
            </div> */}
          </div>
          <div className="row row-2 content-row content-row-mobile">
            <div className={`info-content cell-0`}>{story.csaSchedule}</div>
            <div className={`info-content cell-1`}>
              {story.potentialForAbuse}
            </div>
            {/* <div className={`info-content cell-2`}>{story.approvalHistory}</div> */}
          </div>

          <div className="row row-3 title-row title-row-mobile">
            <div className={`info-title cell-0`}>
              {data.stories.infoHeadings.approvalHistory}
            </div>
          </div>
          <div className="row row-3 content-row content-row-mobile">
            <div className={`info-content cell-0`}>{story.approvalHistory}</div>
          </div>

          <div className="button-row">
            <button
              className="back-button-mobile"
              onClick={backClicked}
              ref={backButton}
            >
              <ArrowIcon />
            </button>

            <button
              className="overview-button"
              onClick={overviewClicked}
              ref={overviewButton}
            >
              {data.stories.overview}
            </button>
            <div className="button-row-right">
              <button
                className="back-button"
                onClick={backClicked}
                ref={backButton}
              >
                <ArrowIcon />
              </button>
              <button
                className="next-button"
                onClick={nextClicked}
                ref={nextButton}
              >
                <ArrowIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="story-right"
        style={{ backgroundColor: story.colors.secondary }}
      >
        <div className="story-titles">
          <h1
            className="title title-1"
            ref={title1}
            style={{
              WebkitTextStroke: "1.5px " + story.colors.primary,
              textStroke: "1.5px " + story.colors.primary,
            }}
          >
            {data.stories.storyTitles[0]}
          </h1>
          <h1
            className="title title-2"
            ref={title2}
            style={{
              color: story.colors.primary,
              background: story.colors.secondary,
            }}
          >
            {story.person.name}
          </h1>
          <h1
            className="title title-3"
            ref={title3}
            style={{
              WebkitTextStroke: "1.5px " + story.colors.primary,
              textStroke: "1.5px " + story.colors.primary,
            }}
          >
            {data.stories.storyTitles[1]}
          </h1>
        </div>

        {story.audio ? (
          <div className="audio-player-container">
            <AudioPlayer story={story} />
          </div>
        ) : null}

        <div className="script">
          {story.script
            ? story.script.map((t, i) => {
                return (
                  <p
                    key={"script_" + i}
                    style={{
                      color: story.colors.primary,
                    }}
                    className={`text ${t.style || null}`}
                  >
                    {t.title ? (
                      <span
                        style={{
                          color: story.colors.primary,
                        }}
                        className="small-title"
                      >
                        {t.title}
                      </span>
                    ) : null}
                    {parse(t.text)}
                  </p>
                );
              })
            : null}
          {story.script ? (
            <p
              style={{
                color: story.colors.primary,
              }}
              className="end"
            >
              {data.stories.end}
            </p>
          ) : null}
        </div>

        {data.stories.blocks.map((b, i) => {
          return (
            <div key={"story-block_" + i} className="bottom-block">
              <div
                style={{
                  color: story.colors.secondary,
                  background: story.colors.primary,
                }}
                className="bottom-block-title"
              >
                {b.title}
              </div>
              <div
                style={{
                  color: story.colors.primary,
                  border: "1px solid " + story.colors.primary,
                }}
                className="bottom-block-text"
              >
                {parse(b.text)}
                {b.link ? (
                  <>
                    <br />
                    <br />
                    <a
                      style={{ color: story.colors.primary }}
                      href={b.link}
                      target="_blank"
                    >
                      {b.linkText}
                    </a>
                    <br />
                    <br />
                    <div
                      style={{ color: story.colors.primary }}
                      className="warning"
                    >
                      {data.stories.warning}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          );
        })}
        <button className="how-to-quit-button" onClick={quitButtonClicked}>
          {data.stories.quitButton}
        </button>
      </div>
    </div>
  );
}

export default Story;
