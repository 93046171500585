import "./Menu.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";

import { ReactComponent as MenuIcon } from "../../assets/images/menu/menu.svg";
import { ReactComponent as HomeIcon } from "../../assets/images/menu/home.svg";
import { ReactComponent as StoriesIcon } from "../../assets/images/menu/stories.svg";
import { ReactComponent as BackgroundIcon } from "../../assets/images/menu/background.svg";
import { ReactComponent as QuitIcon } from "../../assets/images/menu/quit.svg";
import { ReactComponent as ActIcon } from "../../assets/images/menu/act.svg";
import useStore from "../../store";
import { useLocation } from "react-router-dom";

function Menu(props) {
  const el = useRef();
  const tl = useRef();

  const menuItems = useRef([]);
  const menuItemsMobile = useRef([]);
  const [showingMenu, setShowingMenu] = useState(false);

  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);

  const location = useLocation();
  const { hash, pathname, search } = location;

  useEffect(() => {
    // intro
  }, []);

  //TODO: Listen for change in appState
  // use this to hide the open menu if showingMenu
  // then in the click handlers here, simply setAppState()
  // then this new listner will handle close

  const menuClicked = () => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.0 });
    if (showingMenu) {
      // hide
      tl.current.to(menuItems.current, {
        stagger: {
          each: 0.15,
          from: "end",
        },
        autoAlpha: 0,
        duration: 0.15,
        //y: "-10vw",
        x: -8,
        ease: "power4.in",
        onComplete: () => {
          setShowingMenu(false);
        },
      });

      //mobile
      tl.current.to(
        menuItemsMobile.current,
        {
          stagger: {
            each: 0.15,
            from: "end",
          },
          autoAlpha: 0,
          duration: 0.15,
          //y: "-10vw",
          x: 0,
          y: -8,
          ease: "power4.in",
          // onComplete: () => {
          //   setShowingMenu(false);
          // },
        },
        0
      );
      tl.current.set(menuItemsMobile.current, { "pointer-events": "none" });
    } else {
      //show
      console.log(menuItems.current);
      setShowingMenu(true);
      // tl.current.set(el.current, { zIndex: 2000 });
      tl.current.set(menuItems.current, { x: -8, y: "0", autoAlpha: 0 });
      tl.current.set(menuItemsMobile.current, {
        x: 0,
        y: -8,
        autoAlpha: 0,
        "pointer-events": "all",
      });

      tl.current.to(menuItems.current, {
        stagger: {
          each: 0.15,
          from: "start",
        },
        autoAlpha: 1,
        duration: 0.15,
        y: 0,
        x: 0,
        ease: "power4.out",
        // onComplete: () => {
        //   setShowingMenu(true);
        // },
      });

      //mobile
      tl.current.to(
        menuItemsMobile.current,
        {
          stagger: {
            each: 0.15,
            from: "start",
          },
          autoAlpha: 1,
          duration: 0.15,
          y: 0,
          x: 0,
          ease: "power4.out",
          // onComplete: () => {
          //   setShowingMenu(true);
          // },
        },
        0
      );
    }
  };

  const menuButtonClicked = (e) => {
    const link = e.currentTarget.dataset.link;
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.0 });
    //hide menu
    tl.current.to(menuItems.current, {
      stagger: {
        each: 0.15,
        from: "end",
      },
      autoAlpha: 0,
      duration: 0.15,
      //y: "-10vw",
      x: -8,
      ease: "power4.in",
      onComplete: () => {
        setShowingMenu(false);
        setAppState("/" + link);
      },
    });

    tl.current.to(
      menuItemsMobile.current,
      {
        stagger: {
          each: 0.15,
          from: "end",
        },
        autoAlpha: 0,
        duration: 0.15,
        //y: "-10vw",
        x: 0,
        y: -8,
        ease: "power4.in",
        // onComplete: () => {
        //   setShowingMenu(false);
        // },
      },
      0
    );
    tl.current.set(menuItemsMobile.current, { "pointer-events": "none" });
  };

  return (
    <div ref={el} className={`menu`}>
      <button className="menu-button" onClick={menuClicked}>
        <MenuIcon />
      </button>
      <div className="menu-buttons">
        <button
          className="menu-button"
          data-link="../../"
          onClick={menuButtonClicked}
          ref={(el) => (menuItems.current[0] = el)}
        >
          <HomeIcon />
        </button>
        <button
          className="menu-button"
          data-link="stories"
          onClick={menuButtonClicked}
          ref={(el) => (menuItems.current[1] = el)}
        >
          <StoriesIcon />
        </button>
        <button
          className="menu-button"
          data-link="background"
          onClick={menuButtonClicked}
          ref={(el) => (menuItems.current[2] = el)}
        >
          <BackgroundIcon />
        </button>
        <button
          className="menu-button"
          data-link="quit"
          onClick={menuButtonClicked}
          ref={(el) => (menuItems.current[3] = el)}
        >
          <QuitIcon />
        </button>
        <button
          className="menu-button"
          data-link="act"
          onClick={menuButtonClicked}
          ref={(el) => (menuItems.current[4] = el)}
        >
          <ActIcon />
        </button>
      </div>
      <div className={`mobile-menu-buttons ${showingMenu ? "active" : ""}`}>
        <button
          className="menu-button"
          data-link="../../"
          onClick={menuButtonClicked}
          ref={(el) => (menuItemsMobile.current[0] = el)}
        >
          <HomeIcon />
        </button>
        <button
          className="menu-button"
          data-link="stories"
          onClick={menuButtonClicked}
          ref={(el) => (menuItemsMobile.current[1] = el)}
        >
          <StoriesIcon />
        </button>
        <button
          className="menu-button"
          data-link="background"
          onClick={menuButtonClicked}
          ref={(el) => (menuItemsMobile.current[2] = el)}
        >
          <BackgroundIcon />
        </button>
        <button
          className="menu-button"
          data-link="quit"
          onClick={menuButtonClicked}
          ref={(el) => (menuItemsMobile.current[3] = el)}
        >
          <QuitIcon />
        </button>
        <button
          className="menu-button"
          data-link="act"
          onClick={menuButtonClicked}
          ref={(el) => (menuItemsMobile.current[4] = el)}
        >
          <ActIcon />
        </button>
      </div>
    </div>
  );
}

export default Menu;
