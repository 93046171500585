import "./StoriesGrid.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import useStore from "../../store";
import gsap from "gsap";

const compareByName = (a, b) => {
  return a.person.name.localeCompare(b.person.name);
};

const compareByStrength = (a, b) => {
  return a.strengthValue - b.strengthValue;
};
const compareByPopularity = (a, b) => {
  return a.popularity - b.popularity;
};

function StoriesGrid() {
  const el = useRef();
  const tl = useRef();
  const tl2 = useRef();

  const direction = useRef("");
  const y = useRef(0);

  const [gridContent, setGridContent] = useState(null);

  const storiesGridStyle = useStore((state) => state.storiesGridStyle);
  const storiesGridSort = useStore((state) => state.storiesGridSort);

  const timelines = useRef([]);
  const cells = useRef([]);
  const rows = useRef([]);

  const BuildGridContent = () => {
    const stories = data.stories.stories;

    switch (storiesGridSort) {
      case "name":
        stories.sort(compareByName);
        break;
      case "popularity":
        stories.sort(compareByPopularity);
        break;
      case "strength":
        stories.sort(compareByStrength);
        break;
      default:
        stories.sort(compareByPopularity);
        break;
    }

    cells.current = [];
    rows.current = [];

    const mouseMove = (e) => {
      if (e.pageY > y.current) {
        direction.current = "up";
      } else {
        direction.current = "down";
      }
      y.current = e.pageY;
    };

    const storyClicked = (e) => {
      const slug = e.currentTarget.dataset.slug;
      if (!slug) return;
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline();
      tl.current.to(document.querySelector("#root"), {
        autoAlpha: 0,
        duration: 0.5,
      });
      tl.current.call(() => {
        window.location.href = "../../stories/" + slug.toLowerCase();
      });
    };

    return (
      <div
        ref={el}
        className={`stories-grid-inner ${storiesGridStyle}-style`}
        onMouseMove={mouseMove}
      >
        <div className="headings">
          {data.stories.listHeadings.map((h, j) => {
            return (
              <div key={"list_headings_" + j} className={`heading col-${j}`}>
                {h}
              </div>
            );
          })}
        </div>
        {stories.map((s, i) => {
          if (storiesGridStyle && storiesGridStyle.toLowerCase() === "list") {
            const over = (e) => {
              const t = e.currentTarget;
              const tl = gsap.timeline();

              tl.fromTo(
                t,
                {
                  "background-position-y":
                    direction.current === "up" ? "100%" : "-100%",
                },
                {
                  "background-position-y": "0%",
                  duration: 0.3,
                  ease: "sine.out",
                }
              );
              tl.to(
                t.querySelector(".pill"),
                {
                  scale: 1.15,
                  x: 15,
                  duration: 0.5,
                  ease: "sine.out",
                  filter: "drop-shadow(0px 6px 6px rgba(0,0,0,.4)",
                },
                0
              );
              tl.to(
                t.querySelector(".name-wrapper"),
                {
                  "margin-top": 0,
                  "margin-bottom": "-4.4vw",
                  duration: 0.3,
                  ease: "sine.out",
                },
                0
              );
            };

            const out = (e) => {
              const t = e.currentTarget;
              const tl = gsap.timeline();

              tl.to(t, {
                "background-position-y":
                  direction.current === "up" ? "-100%" : "100%",
                duration: 0.3,
                ease: "sine.out",
              });
              tl.to(
                t.querySelector(".pill"),
                {
                  scale: 1.0,
                  x: 0,
                  duration: 0.5,
                  ease: "sine.out",
                  filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0)",
                },
                0
              );
              tl.to(
                t.querySelector(".name-wrapper"),
                {
                  "margin-top": "-4.4vw",
                  "margin-bottom": "0vw",
                  duration: 0.3,
                  ease: "sine.out",
                },
                0
              );
            };

            return (
              <div
                key={"story_" + i}
                className="story-block"
                data-slug={s.slug}
                onClick={storyClicked}
                onMouseOver={over}
                onMouseOut={out}
                style={{
                  backgroundImage:
                    "linear-gradient( to bottom, " +
                    s.colors.secondary +
                    " 50%, transparent 0)",
                  backgroundSize: "100% 200%",
                  backgroundPositionY: "100%",
                }}
                ref={(el) => (cells.current[i] = el)}
              >
                <div className="story-cell cell-0">
                  <img className="pill" src={s.image} alt="" />
                </div>
                <div className="story-cell cell-1">
                  <div className="name-wrapper-mask">
                    <div className="name-wrapper">
                      <div
                        className="solid"
                        style={{ color: s.colors.primary }}
                      >
                        {s.person.name}
                      </div>
                      <div className="outline">{s.person.name}</div>
                    </div>
                  </div>
                </div>
                <div className="story-cell cell-2">
                  {parse(s.genericName || s.name)}
                </div>
                {/* <div className="story-cell cell-3">{parse(s.company)}</div> */}
                <div className="story-cell cell-4">
                  <div className="pill strength">{s.strength}</div>
                  <div className={`pill color ${s.color}`}>{s.color}</div>
                </div>
              </div>
            );
          } else {
            const over = (e) => {
              const i = e.currentTarget.dataset.index;
              const t = cells.current[i];

              if (timelines.current[i]) timelines.current[i].pause();
              timelines.current[i] = gsap.timeline();

              timelines.current[i].to(t.querySelector(".cell-background"), {
                "mask-size": "240% 240%",
                duration: 0.8,
                ease: "sine.out",
              });
              timelines.current[i].to(
                t.querySelector(".pill-image"),
                {
                  scale: 1.15,
                  duration: 0.8,
                  ease: "sine.out",
                  filter: "drop-shadow(0px 6px 6px rgba(0,0,0,.4)",
                },
                0
              );
              timelines.current[i].to(
                t.querySelector(".cell-1"),
                {
                  color: s.colors.primary,
                  "-webkit-text-stroke": "0px #7B7B7B",
                  //"text-stroke": "0px #7B7B7B",
                  duration: 0.8,
                  ease: "sine.out",
                },
                0
              );
            };

            const out = (e) => {
              const i = e.currentTarget.dataset.index;
              const t = cells.current[i];

              if (timelines.current[i]) timelines.current[i].pause();
              timelines.current[i] = gsap.timeline();

              timelines.current[i].to(t.querySelector(".cell-background"), {
                "mask-size": "0% 0%",
                duration: 0.4,
                ease: "sine.out",
              });

              timelines.current[i].to(
                t.querySelector(".pill-image"),
                {
                  scale: 1.0,
                  duration: 0.4,
                  ease: "sine.out",
                  filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0)",
                },
                0
              );
              timelines.current[i].to(
                t.querySelector(".cell-1"),
                {
                  color: "transparent",
                  "-webkit-text-stroke": "1.5px #7B7B7B",
                  //"text-stroke": "1.5px #7B7B7B",
                  duration: 0.4,
                  ease: "sine.out",
                },
                0
              );
            };
            return (
              <div
                key={"story_" + i}
                className={`story-block grid-style-${s.gridStyle}`}
                data-slug={s.slug}
                onClick={storyClicked}
                onMouseOver={over}
                onMouseOut={out}
                data-index={i}
                ref={(el) => (cells.current[i] = el)}
              >
                <div
                  className="cell-background"
                  style={{
                    backgroundColor: s.colors.secondary,
                    WebkitMaskImage: "url('" + s.shape + "')",
                    maskImage: "url('" + s.shape + "')",
                  }}
                ></div>
                <div className="story-cell-wrapper">
                  <div className="story-cell cell-2">
                    {parse(s.genericName || s.name)}
                  </div>
                  <div className="story-cell cell-4">
                    <div className="pill strength">{s.strength}</div>
                    <div className={`pill color ${s.color}`}>{s.color}</div>
                  </div>
                </div>
                <div className="story-cell cell-0">
                  <img className="pill-image" src={s.image} alt="" />
                </div>
                <div className="story-cell cell-1">{s.person.name}</div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  useEffect(() => {
    // stuff
    // if (!storiesGridStyle || !storiesGridSort) return;
    // if (tl.current) tl.current.pause();
    // tl.current = gsap.timeline({});
    // tl.current.to(el.current, { autoAlpha: 0, y: 50, duration: 0.5 });
    // tl.current.call(() => {
    //   setGridContent(BuildGridContent());
    // });
    // tl.current.to(el.current, {
    //   autoAlpha: 1,
    //   y: 0,
    //   duration: 0.5,
    //   delay: 0.5,
    // });
    // tl.current.to(
    //   ///".story-block",
    //   cells.current,
    //   {
    //     autoAlpha: 1,
    //     stagger: 0.2,
    //     y: 0,
    //     duration: 0.4,
    //     ease: "circ.out",
    //   },
    //   1.2
    // );
  }, []);

  useEffect(() => {
    // stuff
    //console.log("anaimte the gird on here");
    if (!storiesGridStyle || !storiesGridSort) return;
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({});
    // if (".story-block") {
    if (gridContent) {
      tl.current.to(".story-block", {
        autoAlpha: 0,
        y: 150,
        duration: 0.3,
      });
      tl.current.to(el.current, { y: 150, autoAlpha: 0, duration: 0.3 });
    }

    tl.current.call(() => {
      // console.log("build cells");
      setGridContent(BuildGridContent());
    });
  }, [storiesGridStyle, storiesGridSort]);

  useEffect(() => {
    if (!gridContent || !cells.current || !storiesGridStyle || !storiesGridSort)
      return;
    // console.log("gird updated, show it");
    if (tl2.current) tl2.current.pause();
    tl2.current = gsap.timeline({});
    tl2.current.fromTo(
      el.current,
      { y: 150, autoAlpha: 0 },
      {
        autoAlpha: 1,
        duration: 0.5,
        y: 0,
      }
    );

    tl2.current.fromTo(
      ///".story-block",
      cells.current,
      { y: 150, autoAlpha: 0 },
      {
        autoAlpha: 1,
        stagger: 0.2,
        y: 0,
        duration: 0.4,
        ease: "circ.out",
      }
    );
  }, [gridContent]);

  return (
    <div ref={el} className={`stories-grid`}>
      {gridContent}
    </div>
  );
}

export default StoriesGrid;
