import "./Background.scss";
import { useCallback, useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-icon.svg";

import { ReactComponent as Wave1SVG } from "../../assets/images/background-wave-1.svg";
import { ReactComponent as Wave2SVG } from "../../assets/images/background-wave-2.svg";
import { ReactComponent as Wave3SVG } from "../../assets/images/background-wave-3.svg";
import { ReactComponent as Wave1MobileSVG } from "../../assets/images/background-wave-1-mobile.svg";
import { ReactComponent as Wave2MobileSVG } from "../../assets/images/background-wave-2-mobile.svg";
import { ReactComponent as Wave3MobileSVG } from "../../assets/images/background-wave-3-mobile.svg";

import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function Background(props) {
  const el = useRef();
  const tl = useRef();
  const wave1 = useRef();
  const wave2 = useRef();
  const wave3 = useRef();
  const wave1mobile = useRef();
  const wave2mobile = useRef();
  const wave3mobile = useRef();

  useEffect(() => {
    // stuff
    if (wave1.current) {
      gsap.fromTo(
        "#line-mask-1",
        { width: 0 },
        {
          width: "42.6%",
          duration: 7,
          repeat: -1,
          repeatDelay: 2,
          ease: "sine.inOut",
        }
      );
      gsap.fromTo(
        "#line-mask-m-1",
        { width: 0 },
        {
          width: "40.5%",
          duration: 7,
          repeat: -1,
          repeatDelay: 2,
          ease: "sine.inOut",
        }
      );
    }

    if (wave2.current) {
      gsap.fromTo(
        "#line-mask-2",
        { width: 0 },
        {
          width: "13%",
          duration: 3,
          repeat: -1,
          repeatDelay: 2,
          ease: "sine.inOut",
        }
      );
      gsap.fromTo(
        "#line-mask-m-2",
        { width: 0 },
        {
          width: "12%",
          duration: 3,
          repeat: -1,
          repeatDelay: 2,
          ease: "sine.inOut",
        }
      );
    }

    if (wave3.current) {
      gsap.fromTo(
        "#line-mask-3",
        { width: 0 },
        {
          width: "40%",
          duration: 5,
          repeat: -1,
          repeatDelay: 2,
          ease: "sine.inOut",
        }
      );
      gsap.fromTo(
        "#line-mask-m-3",
        { width: 0 },
        {
          width: "40%",
          duration: 5,
          repeat: -1,
          repeatDelay: 2,
          ease: "sine.inOut",
        }
      );
    }
  }, []);

  const scrollContainers = useRef([]);

  const handleMouseDown = useCallback((e) => {
    const ele = e.currentTarget;
    if (!ele) {
      return;
    }
    const startPos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    const handleMouseMove = (e) => {
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      ele.scrollTop = startPos.top - dy;
      ele.scrollLeft = startPos.left - dx;
      updateCursor(ele);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      resetCursor(ele);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }, []);

  const handleTouchStart = useCallback((e) => {
    const ele = e.currentTarget;
    if (!ele) {
      return;
    }
    const touch = e.touches[0];
    const startPos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      x: touch.clientX,
      y: touch.clientY,
    };

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const dx = touch.clientX - startPos.x;
      const dy = touch.clientY - startPos.y;
      ele.scrollTop = startPos.top - dy;
      ele.scrollLeft = startPos.left - dx;
      updateCursor(ele);
    };

    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
      resetCursor(ele);
    };

    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
  }, []);

  const updateCursor = (ele) => {
    ele.style.cursor = "grabbing";
    ele.style.userSelect = "none";
  };

  const resetCursor = (ele) => {
    ele.style.cursor = "grab";
    ele.style.removeProperty("user-select");
  };

  return (
    <div ref={el} className={`background`}>
      <h1 className="title">{parse(data.background.title)}</h1>
      {data.background.blocks.map((b, i) => {
        switch (b.type) {
          case "p":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                {parse(b.text)}
                {b.label && <div className="label">{b.label}</div>}
                <div className="block-p-buttons">
                  {b.buttons &&
                    b.buttons.map((l, j) => {
                      const buttonClicked = (e) => {
                        if (l.style === "orange") {
                          window.location.href = l.link;
                        } else {
                          window.open(l.link);
                        }
                      };
                      return (
                        <button
                          key={"block-p-buttonl" + j}
                          className={`block-p-button ${l.style}`}
                          onClick={buttonClicked}
                        >
                          {l.text}
                        </button>
                      );
                    })}
                </div>
              </div>
            );
            break;
          case "full-image":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                <img src={b.image} alt="" className="image" />
                <img src={b.imageMobile} alt="" className="image-mobile" />
                <div className="caption">{parse(b.caption)}</div>
              </div>
            );
            break;
          case "image-caption":
            return (
              <div
                key={"block_" + i}
                className={`block image-caption ${b.style || null}`}
              >
                <img src={b.image} alt="" className="image" />
                <div className="caption">{parse(b.caption)}</div>
              </div>
            );
            break;
          case "title":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                {b.titles.map((t, j) => {
                  return (
                    <div
                      key={"block_title" + j}
                      className={`block-title ${t.style || null}`}
                    >
                      {parse(t.text)}
                    </div>
                  );
                })}
              </div>
            );
            break;

          case "outline-title":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                <div className="block-subtitle">{parse(b.subTitle)}</div>
                {b.titles.map((t, j) => {
                  return (
                    <div
                      key={"block_title" + j}
                      className={`block-title ${t.style || null}`}
                    >
                      {parse(t.text)}
                    </div>
                  );
                })}
              </div>
            );
            break;
          case "box-row":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                <div className="box-row-container">
                  {b.boxes.map((t, j) => {
                    return (
                      <div
                        key={"box_" + j}
                        className={`box ${t.style || null}`}
                      >
                        <div className="title">
                          <div className="title-title">{parse(t.title)}</div>
                          <div className="title-subtitle">
                            {parse(t.subTitle)}
                          </div>
                        </div>
                        <div className="text">{parse(t.text)}</div>
                        <div className="labels">
                          {t.labels.map((p, k) => {
                            return (
                              <div key={"label_" + k} className="label">
                                {p}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="caption">{parse(b.caption)}</div>
              </div>
            );
            break;
          case "table":
            return (
              <div
                key={"block_" + i}
                className={`block block-table ${b.style || null}`}
              >
                {b.cols.map((t, j) => {
                  return (
                    <div
                      key={"block_col" + j}
                      className={`block-table-col ${t.style || null}`}
                    >
                      <div className="block-table-text-wrapper">
                        {t.caption && (
                          <div className="block-table-caption">
                            {parse(t.caption)}
                          </div>
                        )}
                        <div className="block-table-title">
                          {parse(t.title)}
                        </div>
                        <div className="block-table-text">{parse(t.text)}</div>
                      </div>
                      <div className="buttons">
                        {t.buttons &&
                          t.buttons.map((l, j) => {
                            const buttonClicked = (e) => {
                              window.open(l.link);
                            };
                            return (
                              <button
                                key={"block-table-buttonl" + j}
                                className="block-table-button"
                                onClick={buttonClicked}
                              >
                                {l.text}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
            break;

          case "image-row":
            return (
              <div
                key={"block_" + i}
                className={`block image-row ${b.style || null}`}
              >
                {b.cols.map((t, j) => {
                  return (
                    <div
                      key={"block_image_row_col" + j}
                      className={`block-image-row-col ${t.style || null}`}
                    >
                      <div className="image-wrapper">
                        <img className="image" src={t.image} alt="" />
                        <div className="caption">{parse(t.imageCaption)}</div>
                      </div>
                      {/* <div
                        key={"block_col" + j}
                        className={`block_image_row_col_table_block ${
                          t.style || null
                        }`}
                      >
                        <div className="block-table-text-wrapper">
                          {t.caption && (
                            <div className="block-table-caption">
                              {parse(t.caption)}
                            </div>
                          )}
                          <div className="block-table-title">
                            {parse(t.title)}
                          </div>
                          <div className="block-table-text">
                            {parse(t.text)}
                          </div>
                        </div>
                        <div className="buttons">
                          {t.buttons &&
                            t.buttons.map((l, j) => {
                              const buttonClicked = (e) => {
                                window.open(l.link);
                              };
                              return (
                                <button
                                  key={"block-table-buttonl" + j}
                                  className="block-table-button"
                                  onClick={buttonClicked}
                                >
                                  {l.text}
                                </button>
                              );
                            })}
                        </div>
                      </div> */}
                    </div>
                  );
                })}
              </div>
            );
            break;
          case "pill-table":
            return (
              <>
                <div
                  key={"block_" + i}
                  className={`block pill-table pill-table-desktop ${
                    b.style || null
                  }`}
                >
                  <div className="headings">
                    {b.headings.map((h, j) => {
                      return (
                        <div className={`heading col-${j}`}>{parse(h)}</div>
                      );
                    })}
                  </div>
                  <div className="pills">
                    {b.pills.map((p, j) => {
                      return (
                        <div className={`pill pill-${j}`}>
                          <div className="col col-0">
                            <img src={p.image} className="image" alt="" />
                          </div>
                          <div className="col col-1">{p.opiod}</div>
                          <div className="col col-2">{p.types}</div>
                          <div className="col col-3">{p.use}</div>
                          <div className="col col-4">{p.potency}</div>
                          <div className="col col-5">{p.duration}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  key={"block_" + i}
                  className={`block pill-table pill-table-mobile ${
                    b.style || null
                  }`}
                >
                  <div className="pills">
                    {b.pills.map((p, j) => {
                      return (
                        <div className={`pill pill-${j}`}>
                          <div className="row row-0">
                            <div className="headings">
                              <div className="heading half">
                                {b.headings[0]}
                              </div>
                              <div className="heading half">
                                {b.headings[1]}
                              </div>
                            </div>
                            <div className="content">
                              <div className="col half">
                                <img className="image" src={p.image} alt="" />
                              </div>
                              <div className="col half">{p.opiod}</div>
                            </div>
                          </div>
                          <div className="row row-1">
                            <div className="headings">
                              <div className="heading">{b.headings[2]}</div>
                            </div>
                            <div className="content">
                              <div className="col">{p.types}</div>
                            </div>
                          </div>
                          <div className="row row-2">
                            <div className="headings">
                              <div className="heading">{b.headings[3]}</div>
                            </div>
                            <div className="content">
                              <div className="col">{p.uses}</div>
                            </div>
                          </div>
                          <div className="row row-2">
                            <div className="headings">
                              <div className="heading half">
                                {parse(b.headings[4])}
                              </div>
                              <div className="heading half ">
                                {parse(b.headings[5])}
                              </div>
                            </div>
                            <div className="content">
                              <div className="col half">{p.potency}</div>
                              <div className="col half">{p.duration}</div>
                            </div>
                          </div>
                          {/* <div className="col col-0">
                            <img src={p.image} className="image" alt="" />
                          </div>
                          <div className="col col-1">{p.opiod}</div>
                          <div className="col col-2">{p.types}</div>
                          <div className="col col-3">{p.use}</div>
                          <div className="col col-4">{p.potency}</div>
                          <div className="col col-5">{p.duration}</div> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            );
            break;
          case "scroll-table":
            const scrollBack = () => {
              console.log("scroll abck");
              const scrollArea = document.querySelector(".scrollable-area");
              console.log(scrollArea.offsetWidth, scrollArea.scrollLeft);
              scrollArea.scrollTo({
                top: 0,
                left: scrollArea.scrollLeft - (window.innerWidth / 100) * 33.66,
                behavior: "smooth",
              });
              document
                .querySelector(".scroll-next")
                .classList.remove("disable");

              console.log(scrollArea.scrollLeft);
              if (scrollArea.scrollLeft - (window.innerWidth / 100) * 34 <= 0) {
                document.querySelector(".scroll-back").classList.add("disable");
              } else {
                document
                  .querySelector(".scroll-back")
                  .classList.remove("disable");
              }
            };
            const scrollNext = () => {
              console.log("scroll next");
              const scrollArea = document.querySelector(".scrollable-area");
              console.log(scrollArea.offsetWidth, scrollArea.scrollLeft);
              scrollArea.scrollTo({
                top: 0,
                left: scrollArea.scrollLeft + (window.innerWidth / 100) * 33.66,
                behavior: "smooth",
              });
              document
                .querySelector(".scroll-back")
                .classList.remove("disable");
              if (
                scrollArea.scrollLeft >=
                (window.innerWidth / 100) * 33.66 * 3.1
              ) {
                document.querySelector(".scroll-next").classList.add("disable");
              } else {
                document
                  .querySelector(".scroll-next")
                  .classList.remove("disable");
              }
            };
            return (
              <div
                key={"block_" + i}
                className={`block block-scroll-table ${b.style || null}`}
              >
                <div className="scroll-arrows">
                  <button
                    onClick={scrollBack}
                    className="arrow scroll-back disable"
                  >
                    <ArrowIcon />
                  </button>
                  <button onClick={scrollNext} className="arrow scroll-next">
                    <ArrowIcon />
                  </button>
                </div>
                <div className="scrollable-area">
                  {b.cols.map((t, j) => {
                    return (
                      <div
                        key={"block_col" + j}
                        className={`block-scroll-table-col ${t.style || null}`}
                      >
                        <div className="block-scroll-table-text-wrapper">
                          <div className="tags">
                            {t.tags &&
                              t.tags.map((l, j) => {
                                const buttonClicked = (e) => {
                                  //window.open(l.link);
                                };
                                return (
                                  <button
                                    key={"block-scroll-table-button-" + j}
                                    className="block-scroll-table-button"
                                    onClick={buttonClicked}
                                    style={{ background: l.color }}
                                  >
                                    {l.text}
                                  </button>
                                );
                              })}
                          </div>
                          <div className="block-scroll-table-title">
                            {parse(t.title)}
                          </div>
                          <div className="block-scroll-table-text">
                            {parse(t.text)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
            break;

          case "stepped-block":
            return (
              <div
                key={"block_stepped_" + i}
                className={`block stepped-block ${b.style || null}`}
              >
                <div className="scrollable-area">
                  {b.blocks.map((t, j) => {
                    return (
                      <div
                        key={"block_col" + j}
                        className={`stepped-block-col ${
                          t.style || null
                        } col-${j}`}
                        style={{
                          background: t.color,
                          height: "4.166" * (j + 2) + "vw",
                        }}
                      >
                        <div className="stepped-block-number">
                          {parse(t.number)}
                        </div>
                        <div className="stepped-block-text">
                          {parse(t.text)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
            break;

          case "info-table":
            const _b = b;
            return (
              <div
                key={"info_table_" + i}
                className={`block info-table ${b.style || null}`}
              >
                <div className="info-table-title">{b.title}</div>

                <div className="info-table-headings">
                  {b.headings.map((h, k) => {
                    return (
                      <div
                        className={`heading heading-${k}`}
                        key={"heading_" + k}
                      >
                        {h.text}
                      </div>
                    );
                  })}
                </div>
                <div className="info-table-table">
                  {b.rows.map((t, j) => {
                    return (
                      <div
                        key={"row_" + j}
                        className={`info-table-row ${t.style || null}`}
                      >
                        <div className="index col">{parse(t.index)}</div>
                        <div className="icon col">
                          <img src={t.icon} alt="" />
                        </div>
                        <div className="text col">{parse(t.text)}</div>
                        <div className="link col">
                          <a href={t.link} target="_blank">
                            {parse(t.linkText)}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="info-table-mobile">
                  {b.rows.map((t, j) => {
                    return (
                      <>
                        <div
                          key={"row_" + j}
                          className={`info-table-row heading`}
                        >
                          <div className="col heading">
                            {_b.headings[0].text}
                          </div>
                          <div className="col heading">
                            {_b.headings[1].text}
                          </div>
                        </div>
                        <div key={"row_" + j} className={`info-table-row`}>
                          <div className="index col">{parse(t.index)}</div>
                          <div className="icon col">
                            <img src={t.icon} alt="" />
                          </div>
                        </div>
                        <div
                          key={"row_" + j}
                          className={`info-table-row heading`}
                        >
                          <div className="col heading">
                            {_b.headings[2].text}
                          </div>
                          <div className="col heading">
                            {_b.headings[3].text}
                          </div>
                        </div>
                        <div key={"row_" + j} className={`info-table-row`}>
                          <div className="text col">{parse(t.text)}</div>
                          <div className="link col">
                            <a href={t.link} target="_blank">
                              {parse(t.linkText)}
                            </a>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            );
            break;

          case "brain-block":
            return (
              <div
                key={"block_" + i}
                className={`block brain-block ${b.style || null}`}
              >
                <div className="title">{parse(b.title)}</div>
                <div className="text-block">
                  <div className="heading">{parse(b.heading)}</div>
                  <div className="text">{parse(b.text)}</div>
                </div>
                <img className="image" src={b.image} alt="" />
                <img className="image-mobile" src={b.imageMobile} alt="" />
                <div className="subtext">{parse(b.subtext)}</div>
              </div>
            );
            break;

          case "wave":
            return (
              <div
                key={"block_" + i}
                className={`block wave-block ${b.style || null}`}
              >
                <div className="title1">{parse(b.title1)}</div>
                <div className="title2">{parse(b.title2)}</div>
                {b.style === "wave-1" && (
                  <>
                    <Wave1SVG className="wave-graph" ref={wave1} />
                    <Wave1MobileSVG
                      className="wave-graph-mobile"
                      ref={wave1mobile}
                    />
                  </>
                )}
                {b.style === "wave-2" && (
                  <>
                    <Wave2SVG className="wave-graph" ref={wave2} />
                    <Wave2MobileSVG
                      className="wave-graph-mobile"
                      ref={wave2mobile}
                    />
                  </>
                )}
                {b.style === "wave-3" && (
                  <>
                    <Wave3SVG className="wave-graph" ref={wave3} />
                    <Wave3MobileSVG
                      className="wave-graph-mobile"
                      ref={wave3mobile}
                    />
                  </>
                )}
              </div>
            );
            break;

          case "scrollable-image-row":
            return (
              <div
                key={"block_" + i}
                className={`block scrollable-image-row ${b.style || null}`}
              >
                <div
                  className="scroll-container"
                  ref={(el) => (scrollContainers.current[i] = el)}
                  onMouseDown={handleMouseDown}
                  onTouchStart={handleTouchStart}
                >
                  {b.images.map((_i, j) => {
                    return (
                      <>
                        <div className="image-block">
                          <img src={_i.image} alt="" className="image" />
                          <div className="caption">{_i.caption}</div>
                        </div>
                        {j === 0 && (
                          <img
                            className="drag-icon"
                            src="../../assets/images/background/drag-images-icon.svg"
                            alt="drag to scroll"
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            );
            break;

          case "image-text-block":
            return (
              <div
                key={"block_" + i}
                className={`image-text-block block ${b.style || null}`}
              >
                <div className="text-block">
                  <div className="text">
                    <div className="label">{parse(b.label)}</div>
                    {parse(b.text)}
                  </div>
                </div>
                <div className="image-wrapper">
                  <img className="image" src={b.image} alt="" />
                  <div className="caption">{parse(b.caption)}</div>
                </div>
              </div>
            );
            break;
        }
      })}
    </div>
  );
}

export default Background;
