import "./App.scss";
import {
  Route,
  Router,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import Landing from "./components/Landing/Landing";
import About from "./components/About/About";
import Background from "./components/Background/Background";
import Stories from "./components/Stories/Stories";
import FAQ from "./components/FAQ/FAQ";
import Act from "./components/Act/Act";
import Quit from "./components/Quit/Quit";
import Privacy from "./components/Privacy/Privacy";

import TagManager from "react-gtm-module";
import { Suspense, useEffect, useRef } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import useStore from "./store";
import gsap from "gsap";
import Imprint from "./components/Imprint/Imprint";

const tagManagerArgs = {
  //gtmId: "GTM-533JQPS",
};

function App() {
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);

  const el = useRef();
  const tl = useRef();

  const location = useLocation();
  const { hash, pathname, search } = location;

  useEffect(() => {
    // TODO: Turn on this line once we have GTM Tag / ID
    // TagManager.initialize(tagManagerArgs);
    const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
    if (!isMobileDevice) {
      document.body.classList.add("desktop");
    }
    tl.current = gsap.timeline();
    tl.current.to(el.current, {
      autoAlpha: 1,
      duration: 0.5,
      delay: 0.3,
    });
    setAppState(pathname);
  }, []);

  useEffect(() => {
    // console.log(pathname, appState);
    if (appState === null || pathname === appState) return;
    console.log("change to route:" + appState);
    tl.current = gsap.timeline();
    tl.current.to(el.current, {
      autoAlpha: 0,
      duration: 0.5,
      delay: 0.3,
      onComplete: () => {
        window.location.href = "../.." + appState;
      },
    });
  }, [appState]);

  const params = useParams();
  // console.log(params);
  // Note to force deploy
  return (
    <div ref={el} className="main">
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/background" element={<Background />} />
        <Route path="/stories" element={<Stories />} />
        <Route path="/stories/:slug" element={<Stories />} />
        <Route path="/quit" element={<Quit />} />
        <Route path="/act" element={<Act />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/imprint" element={<Imprint />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
