import "./Quit.scss";
import { Fragment, useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-icon.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Quit(props) {
  const el = useRef();
  const tl = useRef();

  const scrollBoxes = useRef([]);

  useEffect(() => {
    // gsap.registerPlugin(ScrollTrigger);
    // let mm = gsap.matchMedia();
    // mm.add("(min-width: 800px)", () => {
    //   scrollBoxes.current.forEach((b) => {
    //     const p = { progress: 0 };
    //     gsap.fromTo(
    //       p,
    //       { progress: 0 },
    //       {
    //         progress: 1,
    //         scrollTrigger: {
    //           trigger: b,
    //           start: "top+=35% bottom",
    //           end: "bottom top+=10%",
    //           //markers: true,
    //           scrub: true,
    //         },
    //         onUpdate: () => {
    //           //console.log(b.offsetWidth, b.scrollLeft);
    //           b.scrollTo({
    //             top: 0,
    //             left: b.scrollWidth * 1.0 * p.progress,
    //             //behavior: "smooth",
    //           });
    //           b.style.transform = "translate(0," + p.progress * 44 + "vw)";
    //         },
    //       }
    //     );
    //   });
    // });
    // mm.add("(max-width: 799px)", () => {
    //   // mobile setup code here...
    // });
    // const onResize = () => {
    //   ScrollTrigger.refresh();
    // };
    // document.addEventListener("resize", onResize);
    // return () => {
    //   document.removeEventListener("resize", onResize);
    // };
  }, []);

  return (
    <div ref={el} className={`quit`}>
      <h1 className="title">{parse(data.quit.title)}</h1>
      {data.quit.blocks.map((b, i) => {
        switch (b.type) {
          case "p":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                {parse(b.text)}
                {b.label && <div className="block-p-label">{b.label}</div>}
                <div className="block-p-buttons">
                  {b.buttons &&
                    b.buttons.map((l, j) => {
                      const buttonClicked = (e) => {
                        if (l.style === "orange") {
                          window.location.href = l.link;
                        } else {
                          window.open(l.link);
                        }
                      };
                      return (
                        <button
                          key={"block-p-buttonl" + j}
                          className={`block-p-button ${l.style}`}
                          onClick={buttonClicked}
                        >
                          {l.text}
                        </button>
                      );
                    })}
                </div>
              </div>
            );
            break;
          case "title":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                {b.titles.map((t, j) => {
                  return (
                    <div
                      key={"block_title" + j}
                      className={`block-title ${t.style || null}`}
                    >
                      {parse(t.text)}
                    </div>
                  );
                })}
              </div>
            );
            break;
          case "outline-title":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                <div className="block-subtitle">{parse(b.subTitle)}</div>
                {b.titles.map((t, j) => {
                  return (
                    <div
                      key={"block_title" + j}
                      className={`block-title ${t.style || null}`}
                    >
                      {parse(t.text)}
                    </div>
                  );
                })}
              </div>
            );
            break;
          case "table":
            return (
              <div
                key={"block_" + i}
                className={`block block-table ${b.style || null}`}
              >
                {b.cols.map((t, j) => {
                  return (
                    <div
                      key={"block_col" + j}
                      className={`block-table-col ${t.style || null}`}
                    >
                      <div className="block-table-text-wrapper">
                        {t.caption && (
                          <div className="block-table-caption">
                            {parse(t.caption)}
                          </div>
                        )}
                        <div className="block-table-title">
                          {parse(t.title)}
                        </div>
                        <div className="block-table-text">{parse(t.text)}</div>
                      </div>
                      <div className="buttons">
                        {t.buttons &&
                          t.buttons.map((l, j) => {
                            const buttonClicked = (e) => {
                              window.open(l.link);
                            };
                            return (
                              <button
                                key={"block-table-buttonl" + j}
                                className="block-table-button"
                                onClick={buttonClicked}
                              >
                                {l.text}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
            break;

          case "image-row":
            return (
              <div
                key={"block_" + i}
                className={`block image-row ${b.style || null}`}
              >
                {b.cols.map((t, j) => {
                  return (
                    <div
                      key={"block_image_row_col" + j}
                      className={`block-image-row-col ${t.style || null}`}
                    >
                      <div className="image-wrapper">
                        <img className="image" src={t.image} alt="" />
                        <div className="caption">{parse(t.imageCaption)}</div>
                      </div>
                      <div
                        key={"block_col" + j}
                        className={`block_image_row_col_table_block ${
                          t.style || null
                        }`}
                      >
                        <div className="block-table-text-wrapper">
                          {t.caption && (
                            <div className="block-table-caption">
                              {parse(t.caption)}
                            </div>
                          )}
                          <div className="block-table-title">
                            {parse(t.title)}
                          </div>
                          <div className="block-table-text">
                            {parse(t.text)}
                          </div>
                        </div>
                        <div className="buttons">
                          {t.buttons &&
                            t.buttons.map((l, j) => {
                              const buttonClicked = (e) => {
                                window.open(l.link);
                              };
                              return (
                                <button
                                  key={"block-table-buttonl" + j}
                                  className="block-table-button"
                                  onClick={buttonClicked}
                                >
                                  {l.text}
                                </button>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
            break;

          case "scroll-table":
            const scrollBack = () => {
              console.log("scroll abck");
              const scrollArea = document.querySelector(".scrollable-area");
              console.log(scrollArea.offsetWidth, scrollArea.scrollLeft);
              scrollArea.scrollTo({
                top: 0,
                left: scrollArea.scrollLeft - (window.innerWidth / 100) * 33.66,
                behavior: "smooth",
              });
              document
                .querySelector(".scroll-next")
                .classList.remove("disable");

              console.log(scrollArea.scrollLeft);
              if (scrollArea.scrollLeft - (window.innerWidth / 100) * 34 <= 0) {
                document.querySelector(".scroll-back").classList.add("disable");
              } else {
                document
                  .querySelector(".scroll-back")
                  .classList.remove("disable");
              }
            };
            const scrollNext = () => {
              console.log("scroll next");
              const scrollArea = document.querySelector(".scrollable-area");
              console.log(scrollArea.offsetWidth, scrollArea.scrollLeft);
              scrollArea.scrollTo({
                top: 0,
                left: scrollArea.scrollLeft + (window.innerWidth / 100) * 33.66,
                behavior: "smooth",
              });
              document
                .querySelector(".scroll-back")
                .classList.remove("disable");
              if (
                scrollArea.scrollLeft >=
                (window.innerWidth / 100) * 33.66 * 3.1
              ) {
                document.querySelector(".scroll-next").classList.add("disable");
              } else {
                document
                  .querySelector(".scroll-next")
                  .classList.remove("disable");
              }
            };
            return (
              <div
                key={"block_" + i}
                className={`block block-scroll-table ${b.style || null}`}
              >
                <div className="scroll-arrows">
                  <button
                    onClick={scrollBack}
                    className="arrow scroll-back disable"
                  >
                    <ArrowIcon />
                  </button>
                  <button onClick={scrollNext} className="arrow scroll-next">
                    <ArrowIcon />
                  </button>
                </div>
                <div className="scrollable-area">
                  {b.cols.map((t, j) => {
                    return (
                      <div
                        key={"block_col" + j}
                        className={`block-scroll-table-col ${t.style || null}`}
                      >
                        <div className="block-scroll-table-text-wrapper">
                          <div className="tags">
                            {t.tags &&
                              t.tags.map((l, j) => {
                                const buttonClicked = (e) => {
                                  //window.open(l.link);
                                };
                                return (
                                  <button
                                    key={"block-scroll-table-button-" + j}
                                    className="block-scroll-table-button"
                                    onClick={buttonClicked}
                                    style={{ background: l.color }}
                                  >
                                    {l.text}
                                  </button>
                                );
                              })}
                          </div>
                          <div className="block-scroll-table-title">
                            {parse(t.title)}
                          </div>
                          <div className="block-scroll-table-text">
                            {parse(t.text)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
            break;

          case "stepped-block":
            const scrollBack2 = (e) => {
              const __i = e.currentTarget.dataset.index;
              const scrollArea =
                scrollBoxes.current[__i].querySelector(".scrollable-area");
              console.log(scrollArea.offsetWidth, scrollArea.scrollLeft);
              scrollArea.scrollTo({
                top: 0,
                left: scrollArea.scrollLeft - (window.innerWidth / 100) * 33.66,
                behavior: "smooth",
              });
              scrollBoxes.current[__i]
                .querySelector(".scroll-next")
                .classList.remove("disable");

              console.log(scrollArea.scrollLeft);
              if (scrollArea.scrollLeft - (window.innerWidth / 100) * 34 <= 0) {
                scrollBoxes.current[__i]
                  .querySelector(".scroll-back")
                  .classList.add("disable");
              } else {
                scrollBoxes.current[__i]
                  .querySelector(".scroll-back")
                  .classList.remove("disable");
              }
            };
            const scrollNext2 = (e) => {
              const __i = e.currentTarget.dataset.index;
              const scrollArea =
                scrollBoxes.current[__i].querySelector(".scrollable-area");
              console.log(scrollArea.offsetWidth, scrollArea.scrollLeft);
              scrollArea.scrollTo({
                top: 0,
                left: scrollArea.scrollLeft + (window.innerWidth / 100) * 33.66,
                behavior: "smooth",
              });
              scrollBoxes.current[__i]
                .querySelector(".scroll-back")
                .classList.remove("disable");
              if (
                scrollArea.scrollLeft >=
                (window.innerWidth / 100) * 33.66 * 3.1
              ) {
                scrollBoxes.current[__i]
                  .querySelector(".scroll-next")
                  .classList.add("disable");
              } else {
                scrollBoxes.current[__i]
                  .querySelector(".scroll-next")
                  .classList.remove("disable");
              }
            };

            return (
              <div
                key={"block_stepped_" + i}
                className={`block stepped-block ${b.style || null}`}
                ref={(el) => (scrollBoxes.current[i] = el)}
              >
                <div className="scroll-arrows">
                  <button
                    onClick={scrollBack2}
                    className="arrow scroll-back disable"
                    data-index={i}
                  >
                    <ArrowIcon />
                  </button>
                  <button
                    onClick={scrollNext2}
                    className="arrow scroll-next"
                    data-index={i}
                  >
                    <ArrowIcon />
                  </button>
                </div>
                <div className="scrollable-area">
                  {b.blocks.map((t, j) => {
                    return (
                      <div
                        key={"block_col" + j}
                        className={`stepped-block-col ${
                          t.style || null
                        } col-${j}`}
                        style={{
                          background: t.color,
                          height: "4.166" * (j + 2) + "vw",
                        }}
                      >
                        <div className="stepped-block-number">
                          {parse(t.number)}
                        </div>
                        <div className="stepped-block-text">
                          {parse(t.text)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
            break;

          case "info-table":
            const _b = b;
            return (
              <div
                key={"info_table_" + i}
                className={`block info-table ${b.style || null}`}
              >
                <div className="info-table-title">{b.title}</div>

                <div className="info-table-headings">
                  {b.headings.map((h, k) => {
                    return (
                      <div
                        className={`heading heading-${k}`}
                        key={"heading_" + k}
                      >
                        {h.text}
                      </div>
                    );
                  })}
                </div>
                <div className="info-table-table">
                  {b.rows.map((t, j) => {
                    return (
                      <div
                        key={"row__" + j}
                        className={`info-table-row ${t.style || null}`}
                      >
                        <div className="index col">{parse(t.index)}</div>
                        <div className="icon col">
                          <img src={t.icon} alt="" />
                        </div>
                        <div className="text col">{parse(t.text)}</div>
                        <div className="link col">
                          <a href={t.link} target="_blank">
                            {parse(t.linkText)}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="info-table-mobile">
                  {b.rows.map((t, j) => {
                    return (
                      <Fragment key={"adsadsa" + j}>
                        <div
                          key={"row___" + j}
                          className={`info-table-row heading`}
                        >
                          <div className="col heading">
                            {_b.headings[0].text}
                          </div>
                          <div className="col heading">
                            {_b.headings[1].text}
                          </div>
                        </div>
                        <div key={"row______" + j} className={`info-table-row`}>
                          <div className="index col">{parse(t.index)}</div>
                          <div className="icon col">
                            <img src={t.icon} alt="" />
                          </div>
                        </div>
                        <div
                          key={"row_" + j}
                          className={`info-table-row heading`}
                        >
                          <div className="col heading">
                            {_b.headings[2].text}
                          </div>
                          <div className="col heading">
                            {_b.headings[3].text}
                          </div>
                        </div>
                        <div key={"row_____" + j} className={`info-table-row`}>
                          <div className="text col">{parse(t.text)}</div>
                          <div className="link col">
                            <a href={t.link} target="_blank">
                              {parse(t.linkText)}
                            </a>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            );
            break;
        }
      })}
    </div>
  );
}

export default Quit;
