import "./Stories.scss";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";
import StoriesGrid from "../StoriesGrid/StoriesGrid";
import useStore from "../../store";
import Story from "../Story/Story";

function Stories({}) {
  const el = useRef();
  const tl = useRef();
  const title = useRef();
  const params = useParams();
  const location = useLocation();
  const [story, setStory] = useState(null);

  const listTitle = useRef();

  const setStoriesGridStyle = useStore((state) => state.setStoriesGridStyle);
  const setStoriesGridSort = useStore((state) => state.setStoriesGridSort);
  const storiesGridStyle = useStore((state) => state.storiesGridStyle);
  const storiesGridSort = useStore((state) => state.storiesGridSort);

  useEffect(() => {
    if (params.slug) {
      // check for matching story and get data
      data.stories.stories.forEach((s, i) => {
        if (s.slug.toLowerCase() === params.slug.toLowerCase()) {
          setStory(data.stories.stories[i]);
          console.log(data.stories.stories[i]);
        }
      });
    }

    // intro
    // TODO: add story vs story if check and specific animatin ehre
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.5 });

    tl.current.fromTo(
      [title.current],
      { autoAlpha: 0, y: 100 },
      { stagger: 0.3, autoAlpha: 1, y: 0, duration: 1 }
    );
    tl.current.call(
      () => {
        setStoriesGridStyle("grid");
        setStoriesGridSort("popularity");
      },
      null,
      0
    );
  }, []);

  useEffect(() => {
    if (!storiesGridStyle) return;

    const options = document.querySelectorAll(".heading-content .option");
    options.forEach((o, i) => {
      if (
        o.dataset.option.toLowerCase() === "grid" ||
        o.dataset.option.toLowerCase() === "list"
      ) {
        if (o.dataset.option.toLowerCase() === storiesGridStyle.toLowerCase()) {
          o.classList.add("active");
        } else {
          o.classList.remove("active");
        }
      }
    });
  }, [storiesGridStyle]);

  useEffect(() => {
    if (!storiesGridSort) return;

    const options = document.querySelectorAll(".heading-content .option");
    options.forEach((o, i) => {
      if (
        o.dataset.option.toLowerCase() !== "grid" &&
        o.dataset.option.toLowerCase() !== "list"
      ) {
        if (o.dataset.option.toLowerCase() === storiesGridSort.toLowerCase()) {
          o.classList.add("active");
        } else {
          o.classList.remove("active");
        }
      }
    });
  }, [storiesGridSort]);

  return (
    <div ref={el} className={`stories`}>
      {story ? (
        <Story story={story} />
      ) : (
        <>
          <div className="stories-main">
            <h1 ref={title} className="title">
              {parse(data.stories.title)}
            </h1>
          </div>
          <div className="stories-list">
            <div className="stories-headings">
              {data.stories.rows.map((r, l) => {
                return r.headings.map((h, j) => {
                  return (
                    <div
                      key={"story_heading_" + j}
                      className={`heading col-${h.col}`}
                    >
                      {h.title}
                    </div>
                  );
                });
              })}
            </div>
            <div className="stories-headings-content">
              {data.stories.rows.map((r, l) => {
                return r.headings.map((h, j) => {
                  const optionClicked = (e) => {
                    const data = e.currentTarget;
                    console.log(data.dataset);
                    if (
                      data.dataset.option === "Grid" ||
                      data.dataset.option === "List"
                    ) {
                      setStoriesGridStyle(data.dataset.option.toLowerCase());
                    } else {
                      setStoriesGridSort(data.dataset.option.toLowerCase());
                    }
                  };
                  return (
                    <div
                      key={"story_heading_content_" + j}
                      className={`heading-content col-${h.col}`}
                    >
                      {h.description
                        ? h.description
                        : h.options.map((o, k) => {
                            return (
                              <div
                                key={"story_option_" + k}
                                onClick={optionClicked}
                                data-option={o}
                                className="option"
                              >
                                {o === "Popularity" ? "Prevalence" : o}
                              </div>
                            );
                          })}
                    </div>
                  );
                });
              })}
            </div>
            <div className="stories-list-mobile">
              {data.stories.rows.map((r, l) => {
                return (
                  <div key={"storymobile_row" + l} className="stories-row">
                    <div className="stories-headings">
                      {r.headings.map((h, i) => {
                        return (
                          <div
                            key={"story_heading_" + i}
                            className={`heading col-${h.col}`}
                          >
                            {h.title}
                          </div>
                        );
                      })}
                    </div>
                    <div className="stories-headings-content">
                      {r.headings.map((h, i) => {
                        const optionClicked = (e) => {
                          const data = e.currentTarget;
                          console.log(data.dataset);
                          if (
                            data.dataset.option === "Grid" ||
                            data.dataset.option === "List"
                          ) {
                            setStoriesGridStyle(
                              data.dataset.option.toLowerCase()
                            );
                          } else {
                            setStoriesGridSort(
                              data.dataset.option.toLowerCase()
                            );
                          }
                        };
                        return (
                          <div
                            key={"story_heading_content_" + i}
                            className={`heading-content col-${h.col}`}
                          >
                            {h.description
                              ? h.description
                              : h.options.map((o, k) => {
                                  return (
                                    <div
                                      key={"story_option_" + k}
                                      onClick={optionClicked}
                                      data-option={o}
                                      className="option"
                                    >
                                      {o === "Popularity" ? "Prevalence" : o}
                                    </div>
                                  );
                                })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <StoriesGrid />
          </div>
        </>
      )}
    </div>
  );
}

export default Stories;
