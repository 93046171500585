import "./Imprint.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";

function Imprint(props) {
  const el = useRef();
  const tl = useRef();

  useEffect(() => {
    // stuff
  }, []);

  return (
    <div ref={el} className={`imprint`}>
      <h1 className="title">{parse(data.imprint.title)}</h1>
      <h1 className="mobile-title">{parse(data.imprint.mobileTitle)}</h1>
      <div className="content">
        <h2>{parse(data.imprint.content.title)}</h2>
        <p>{parse(data.imprint.content.address)}</p>
        <p>
          {parse(data.imprint.content.phone)}
          <br />
          {parse(data.imprint.content.email)}
        </p>
        <p>{parse(data.imprint.content.website)}</p>
      </div>
    </div>
  );
}

export default Imprint;
