import "./FAQ.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";

import { ReactComponent as QIcon } from "../../assets/images/faq-q.svg";
import { ReactComponent as AIcon } from "../../assets/images/faq-a.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function FAQ(props) {
  const el = useRef();
  const tl = useRef();
  const faqs = useRef();

  //ref={(el) => (menuItems.current[3] = el)}

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const boxes = gsap.utils.toArray(".faq-question, .faq-answer");
    boxes.forEach((box) => {
      gsap.fromTo(
        box,
        { y: "60vh" },
        {
          y: 0,
          ease: "power4.out",
          scrollTrigger: {
            trigger: box,
            start: "top-=300 bottom",
            end: "top bottom",
            scrub: 2.0,
            // markers: true,
          },
        }
      );
    });
  }, []);

  return (
    <div ref={el} className={`faq`}>
      <h1 className="title">{parse(data.faq.title)}</h1>
      <h1 className="mobile-title">{parse(data.faq.mobileTitle)}</h1>
      <h2 className="sub-title">{parse(data.faq.subTitle)}</h2>
      <div ref={faqs} className="faqs">
        {data.faq.faqs.map((f, i) => {
          return (
            <div className="faq-set" key={"faq-set_" + i}>
              <div className="faq-question">
                <QIcon />
                <div className="text">{parse(f.question)}</div>
              </div>
              <div className="faq-answer">
                <div className="text">{parse(f.answer)}</div>
                <AIcon />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FAQ;
