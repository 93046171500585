import "./Landing.scss";
import { Suspense, useEffect, useRef, useState, useMemo } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";
import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useDrag } from "react-use-gesture";
import { a, useSpring } from "@react-spring/three";

import loaderLottie from "../../assets/animations/loading.json";
import {
  AccumulativeShadows,
  Box,
  Environment,
  Html,
  PerspectiveCamera,
  Plane,
  RandomizedLight,
  SoftShadows,
  useGLTF,
  useProgress,
} from "@react-three/drei";
import { easing } from "maath";
import useStore from "../../store";
import StoriesGrid from "../StoriesGrid/StoriesGrid";

function Inspector({ responsiveness = 20, children }) {
  const { size } = useThree();
  const euler = useMemo(() => new THREE.Euler(), []);
  const [spring, set] = useSpring(() => ({
    rotation: [0, 0, 0],
  }));
  const bind = useDrag(({ delta: [dx, dy] }) => {
    euler.y += (dx / size.width) * responsiveness;
    euler.x += (dy / size.width) * responsiveness;
    euler.x = THREE.MathUtils.clamp(euler.x, -Math.PI / 2, Math.PI / 2);
    console.log(euler.toArray().slice(1, 2));
    set({ rotation: [0, euler.toArray().slice(1, 2)[0], 0] });
  });
  return (
    <a.group {...bind()} {...spring}>
      {children}
    </a.group>
  );
}

const PillModel = () => {
  const pill = useRef();
  const landingPill = useStore((state) => state.landingPill);

  const { nodes, materials, scene } = useGLTF(landingPill.model.file);

  useEffect(() => {
    scene.traverse((mesh, i) => {
      mesh.castShadow = true;
      if (mesh.material) {
        if (mesh.material.metalness > 0.4) {
          mesh.material.metalness = 0.4;
        }
      }
    });
    // actions["animation_0"].timeScale = 0.5;
    // actions["animation_0"].play();
  }, [landingPill, scene]);

  useFrame(({ gl, camera, clock }) => {
    //console.log(clock.getDelta());
    pill.current.rotation.y += 0.003;
  });
  const scale = landingPill.model.scale || 8;
  return (
    <mesh castShadow ref={pill} scale={[scale, scale, scale]}>
      <primitive object={scene} />
    </mesh>
  );
};

const Loader = () => {
  const { progress } = useProgress();
  const lottiePlayer = useRef();

  return (
    <Html wrapperClass="loading-wrapper">
      <Player
        // animationData={animationData}
        src={loaderLottie}
        loop
        autoplay
        //ref={lottiePlayer}
        className="player"
        // height={400}
        // width={400}
      />
    </Html>
  );
};

function Landing(props) {
  const el = useRef();
  const tl = useRef();
  const tl2 = useRef();
  const title = useRef();
  const titlem = useRef();
  const subtitle = useRef();
  const upArrow = useRef();
  const downArrow = useRef();
  const phone = useRef();
  const listTitle = useRef();

  const canvas = useRef();
  // const pillIndex = useRef(null);

  const landingPill = useStore((state) => state.landingPill);
  const setLandingPill = useStore((state) => state.setLandingPill);

  const setStoriesGridStyle = useStore((state) => state.setStoriesGridStyle);
  const setStoriesGridSort = useStore((state) => state.setStoriesGridSort);

  useEffect(() => {
    const i = Math.floor(Math.random() * data.stories.stories.length);
    setLandingPill(data.stories.stories[i]);

    // intro
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.5 });

    tl.current.fromTo(
      [title.current, subtitle.current],
      { autoAlpha: 0, y: 100 },
      { stagger: 0.3, autoAlpha: 1, y: 0, duration: 1 }
    );
    tl.current.fromTo(
      [titlem.current],
      { autoAlpha: 0, y: 100 },
      { stagger: 0.3, autoAlpha: 1, y: 0, duration: 1 },
      0
    );
    tl.current.fromTo(
      [upArrow.current, downArrow.current, canvas.current],
      { autoAlpha: 0, y: 0 },
      { stagger: 0.2, autoAlpha: 1, y: 0, duration: 0.5 },
      0.5
    );

    tl.current.to(
      [phone.current],
      {
        stagger: 0.2,
        y: 0,
        autoAlpha: 1,
        duration: 0.5,
      },
      1
    );

    tl.current.call(
      () => {
        setStoriesGridStyle("list");
        setStoriesGridSort("popularity");
      },
      null,
      0
    );
  }, []);

  // const nextPill = (e) => {
  //   if (tl2.current) tl.current.pause();
  //   tl2.current = gsap.timeline({});
  //   tl2.current.to([canvas.current, phone.current], {
  //     stagger: 0.2,
  //     y: 10,
  //     autoAlpha: 0,
  //     duration: 0.5,
  //   });
  //   tl2.current.call(() => {
  //     const _i =
  //       pillIndex >= data.stories.stories.length - 1 ? 0 : pillIndex + 1;
  //     setPillIndex(_i);
  //     phone.current.innerHTML = data.stories.stories[_i].phone;
  //   });
  //   tl2.current.to([canvas.current, phone.current], {
  //     stagger: 0.2,
  //     y: 0,
  //     autoAlpha: 1,
  //     duration: 0.5,
  //   });
  // };

  // const previousPill = (e) => {
  //   if (tl2.current) tl.current.pause();
  //   tl2.current = gsap.timeline({});
  //   tl2.current.to([canvas.current, phone.current], {
  //     stagger: 0.2,
  //     y: 10,
  //     autoAlpha: 0,
  //     duration: 0.5,
  //   });
  //   tl2.current.call(() => {
  //     const _i =
  //       pillIndex <= 0 ? data.stories.stories.length - 1 : pillIndex - 1;
  //     setPillIndex(_i);
  //     phone.current.innerHTML = data.stories.stories[_i].phone;
  //   });
  //   tl2.current.to([canvas.current, phone.current], {
  //     stagger: 0.2,
  //     y: 0,
  //     autoAlpha: 1,
  //     duration: 0.5,
  //   });
  // };

  useEffect(() => {
    // console.log(
    //   "landing pill",
    //   landingPill
    //   // data.stories.stories[pillIndex].phone,
    //   // data.stories.stories[pillIndex].model
    // );
  }, [landingPill]);

  return (
    <div ref={el} className={`landing`}>
      <div className="landing-main">
        <h1 ref={title} className="title">
          {parse(data.landing.title)}
        </h1>
        <h1 ref={titlem} className="mobile-title">
          {parse(data.landing.mobileTitle)}
        </h1>
        <h3 ref={phone} className="phone">
          {landingPill != null ? landingPill.phone : ""}
        </h3>
        <h2 ref={subtitle} className="sub-title">
          {parse(data.landing.subTitle)}
        </h2>

        <button ref={upArrow} className="up-arrow">
          <ArrowIcon />
        </button>
        <button ref={downArrow} className="down-arrow">
          <ArrowIcon />
        </button>
        <div ref={canvas} className="pill-canvas">
          <Canvas shadows>
            <Suspense fallback={<Loader />}>
              <PerspectiveCamera
                makeDefault={true}
                fov={30}
                position={[0, 0, 20]}
              />
              <SoftShadows size={100} focus={0.6} samples={12} />
              <Environment files="../../assets/models/brown_photostudio_02_1k.hdr" />
              <directionalLight
                position={[0.3, 3, 3]}
                intensity={3.5}
                shadow-mapSize={512}
              ></directionalLight>
              <directionalLight
                castShadow
                position={[0.2, 0.5, 3]}
                intensity={0.3}
                shadow-mapSize={1024}
              ></directionalLight>
              <Inspector>
                {landingPill !== null ? <PillModel /> : null}
              </Inspector>
              <Plane
                receiveShadow
                args={[40, 40]}
                rotation={[0, 0, 0]}
                position={[0, 0, -6]}
              >
                <shadowMaterial transparent opacity={0.4} color={0x000} />
              </Plane>
            </Suspense>
          </Canvas>
          {/* <Environment files={"./assets/models/kiara_1_dawn_1k.hdr"} /> */}
        </div>
      </div>
      <div className="news">
        <h2 className="news-title">{data.landing.news.title}</h2>
        <div className="news-items">
          {data.landing.news.items.map((n, i) => {
            return (
              <div
                className={`news-item ${i > 0 ? "hide-desktop" : ""}`}
                key={`newsitem-${i}`}
              >
                <div className="news-item-date news-item-block">
                  <div className="title">{data.landing.news.titles[0]}</div>
                  <div className="text">{n.date}</div>
                </div>
                <div className="news-item-title news-item-block">
                  <div className="title">{data.landing.news.titles[1]}</div>
                  <div className="text">{n.title}</div>
                </div>
                <div className="news-item-description news-item-block">
                  <div className="title">{data.landing.news.titles[2]}</div>
                  <div className="text">{parse(n.description)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="landing-list">
        <div className="list-titles" ref={listTitle}>
          {data.landing.listTitle.map((t, i) => {
            return (
              <h2 key={"title_" + i} className={`list-title row-${i}`}>
                {t}
              </h2>
            );
          })}
        </div>
        <StoriesGrid />
      </div>
    </div>
  );
}

export default Landing;
