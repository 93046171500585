import "./Act.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";

function Act(props) {
  const el = useRef();
  const tl = useRef();

  useEffect(() => {
    // stuff
  }, []);

  return (
    <div ref={el} className={`act`}>
      <h1 className="title">{parse(data.act.title)}</h1>
      {data.act.blocks.map((b, i) => {
        switch (b.type) {
          case "p":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                {parse(b.text)}
              </div>
            );
            break;
          case "title":
            return (
              <div key={"block_" + i} className={`block ${b.style || null}`}>
                {b.titles.map((t, j) => {
                  return (
                    <div
                      key={"block_title" + j}
                      className={`block-title ${t.style || null}`}
                    >
                      {parse(t.text)}
                    </div>
                  );
                })}
              </div>
            );
            break;
          case "table":
            return (
              <div
                key={"block_" + i}
                className={`block block-table ${b.style || null}`}
              >
                {b.cols.map((t, j) => {
                  return (
                    <div
                      key={"block_col" + j}
                      className={`block-table-col ${t.style || null}`}
                    >
                      <div className="block-table-text-wrapper">
                        <div className="block-table-title">
                          {parse(t.title)}
                        </div>
                        <div className="block-table-text">{parse(t.text)}</div>
                      </div>
                      <div className="buttons">
                        {t.buttons.map((l, j) => {
                          const buttonClicked = (e) => {
                            window.open(l.link);
                          };
                          return (
                            <button
                              key={"block-table-buttonl" + j}
                              className="block-table-button"
                              onClick={buttonClicked}
                            >
                              {l.text}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
            break;
        }
      })}
    </div>
  );
}

export default Act;
