import "./AudioPlayer.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import useStore from "../../store";
import { useWavesurfer } from "@wavesurfer/react";
import { ReactComponent as PlayIcon } from "../../assets/images/play-icon.svg";

const formatTime = (seconds) =>
  [seconds / 60, seconds % 60]
    .map((v) => `0${Math.floor(v)}`.slice(-2))
    .join(":");

function AudioPlayer({ story }) {
  const el = useRef();
  const tl = useRef();
  const playButton = useRef();
  const playTime = useRef();
  const totalTime = useRef();

  const progressRef = useRef();

  const [highlightPlayButton, setHighlightPlayButton] = useState(false);

  const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
    container: progressRef,
    progressColor: "#f8401c",
    barWidth: 2,
    barGap: 2,
    normalize: false,
    autoPlay: true,
    fillParent: true,
    height: "auto",
    dragToSeek: true,
    barAlign: "center",
    barRadius: 100,
    interact: true,
    normalize: false,
    waveColor: story.colors.primary,
    url: story.audio,
  });

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };

  // const appState = useStore((state) => state.appState);
  // const setAppState = useStore((state) => state.setAppState);

  useEffect(() => {
    // stuff
    console.log("AudioPlayer", story);
  }, []);

  return (
    <div ref={el} className={`audio-player`}>
      <button
        ref={playButton}
        className="play-button"
        onPointerOver={() => {
          setHighlightPlayButton(true);
        }}
        onPointerOut={() => {
          setHighlightPlayButton(false);
        }}
        onPointerLeave={() => {
          setHighlightPlayButton(false);
        }}
        onClick={onPlayPause}
        style={{
          color: highlightPlayButton ? story.colors.primary : "#f8401c",
        }}
      >
        <PlayIcon />
      </button>
      <div className="progress-wrapper">
        <div ref={playTime} className="play-time">
          {formatTime(currentTime)}
        </div>
        <div className="progress" ref={progressRef}></div>
        <div
          style={{ color: story.colors.primary }}
          ref={totalTime}
          className="total-time"
        >
          {formatTime(wavesurfer && wavesurfer.getDuration())}
        </div>
      </div>
    </div>
  );
}

export default AudioPlayer;
